import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { AUTHENTICATION_URL } from "../../../global/utils/Constants";
import "../../../styles/globalStyles.scss";
import MicrosoftLogo from "./MicrosoftLogo";

interface IProps {
    currentPath: string;
}

const MicrosoftLoginButton = ({ currentPath }: IProps) => {


  const handleLoginClick = () => {
    localStorage.setItem("pathBeforeLogin", currentPath);

    // window.location.replace(AUTHENTICATION_URL);
    window.location.href = AUTHENTICATION_URL;
  };

  return (
    <Button
      className="portal-button-light microsoft-login-button"
      onClick={handleLoginClick}
    >
      <MicrosoftLogo />
      <span>LOGIN WITH MICROSOFT</span>
    </Button>
  );
};

export default MicrosoftLoginButton;
