import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IValidationError } from "../../../global/interfaces/IValidationError";
import { AccountFormMode } from "../interfaces/AccountFormMode";
import useGetValidateNewAccountEmailQuery from "../queries/useGetValidateNewAccountEmailQuery";


const DEBOUNCE_TIME_MS = 500;

export const useEmailValidation = (initialEmail: string, mode: AccountFormMode) => {
  const [email, setEmail] = useState(initialEmail);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<string | null>(null);

  const { refetch } = useGetValidateNewAccountEmailQuery(email);

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail(value);
    setAllowSubmit(false);
    setValidationError(null);
    debouncedRefetch(value);
  };

  const debouncedRefetch = useCallback(
    debounce(async (value) => {
      if (value) {
        const { data } = await refetch({ queryKey: ["ValidateNewWebserverUrl", value] });
        handleValidationResult(data)
      }
    }, DEBOUNCE_TIME_MS),
    []
  );

  const handleValidationResult = (validationBEError: IValidationError | undefined) => {


    // check if there is an error object in the list of the errors
    if (validationBEError && validationBEError.validationErrors && validationBEError.validationErrors.length > 0) {
      setAllowSubmit(false);

      let errorMsgs: string[] = []

      // iterate thru each error and create approrpiate validation message
      for (var abk9Error of validationBEError.validationErrors) {
        if (abk9Error.status === 400) {
          const msg = "Email ist ungültig.";
          errorMsgs.push(msg)
          setAllowSubmit(false);

        } else if (abk9Error.status === 403) {
          console.error("User has no permission to validate email but just did.");
        } else if (abk9Error.status === 409) {
          if (mode === AccountFormMode.Create) {
            const msg = "Email ist schon vergeben.";
            errorMsgs.push(msg)
            setAllowSubmit(false);
          } else {
            // In ASSOCIATE or UPDATE mode, ignore 409 error and allow submit
            // check if there are any other errors, if not, allow submit
            if (validationError === null) {
              setValidationError(null);
              setAllowSubmit(true);
            }
          }

        } else {
          console.log(abk9Error)
        }
      }

      // if there are any validation errors, then set them
      if (errorMsgs.length > 0) {
        setValidationError(errorMsgs.join('\r\n'))
      }


    } else {
      // there are no errors
      setValidationError(null);
      setAllowSubmit(true);

    }
  }


  useEffect(() => {
    return () => {
      debouncedRefetch.cancel();
    };
  }, [debouncedRefetch]);

  const emailValidator = (value: string) => {
    if (!value) {
      return "*txt* Dieses Feld ist ein Pflichtfeld.";
    }

    if (validationError) {
      return validationError;
    }

    return "";
  };

  const resetValidator = () => {
    setEmail("");
    setValidationError(null)
  }

  return {
    email,
    handleEmailChange,
    allowSubmit,
    validationError,
    emailValidator,
    resetValidator,
  };
};